import Box from '@mui/material/Box';
import Logo from '../img/logo.png';

export default function PlaceholderImage({sx = {}, ...rest}) {
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        bgcolor: '#efefef',
        justifyContent: 'center',
        padding: 6,
        '& img': {
          width: '100%',
          paddingLeft: '10%',
          paddingRight: '10%',
        },
      }}
      {...rest}
    >
      <img src={Logo} alt="" />
    </Box>
  );
}