import Box from '@mui/material/Box';
import src from '../img/logo.png';

export default function Logo({sx = {}, ...rest}) {
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        maxWidth: 'sm',
        mx: 'auto',
        my: 2,
        '& img': {
          width: '100%',
        },
      }}
      {...rest}
    >
      <img src={src} alt="" />
    </Box>
  );
}