import React from 'react';
import {createRoot} from 'react-dom/client';
import SharedApp from '@scout-web/shared';

import reportWebVitals from './reportWebVitals';

import colors from './colors';
import components from './components';
// import elements from './elements';
import environment from './environment.json';
import images from './images';
import routes from './routes';
import './styles.css';
import translations from './translations';
import vendor from './vendor';

const config = {
  colors,
  components,
  // elements,
  environment,
  images,
  integrations: ['amazon', 'google', 'hue', 'lifx'],
  routes,
  translations,
  vendor,
};

const app = new SharedApp(config);
const App = app.build();

createRoot(document.getElementById('root')).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
