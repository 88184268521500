const kineticOrange = '#ef9123';
const kineticBlue = '#40afc9';
const kineticPink = '#ed1863';
const kineticGreen = '#6bbe59';

export default {
  primaryText: '#58585a',
  armed: kineticGreen,
  ok: kineticGreen,
  paired: kineticGreen,
  warningYellow: kineticOrange,
  warningYellowText: '#fff',
  alarmed: kineticPink,
  primaryButtonBg: kineticBlue,
  helpButtonBg: kineticOrange,
  darkBackground: '#fff',
  background: '#fff',
  firstTab: 'transparent', // tabColor(0),
  secondTab: 'transparent', // tabColor(1),
  thirdTab: 'transparent', // tabColor(2),
  fourthTab: 'transparent', // tabColor(3),
  activeTabColor: kineticGreen,
  inactiveTabColor: '#fff',
  activeTabBackgroundColor: 'transparent',
  inactiveBackgroundColor: 'transparent',
  activeIndicator: kineticGreen,
  pairingIndicator: kineticBlue,
  navigation: '#fff',
  loading: kineticPink,
  accent: kineticBlue,
  toggle: kineticBlue,
  addButton: kineticGreen,
  cameraFooter: kineticBlue,
  item: kineticBlue,
  lock: kineticOrange,
  titleBarButton: '#fff',
  highlightColor: kineticOrange,
  recommendedButton: kineticOrange, // used for select button on recommended monitoring plan
  planButton: kineticGreen, // used for select button on monitoring plans
  splashBg: '#fff',
  splashFg: kineticPink,

  // Login / signup screen
  loginBackButton: '#3a3a3a',
  loginInputBottom: '#3a3a3a',
  loginInputText: '#000',
  loginInputPlaceholderText: '#777',
  loginNavigation: '#3a3a3a',
  loginText: '#3a3a3a',
  loginStatusBar: ['dark-content', 'dark-content'],
  materialInputBottom: '#3a3a3a',
  materialInputText: '#000',
  materialInputPlaceholderText: '#777',

  // Navigation Drawers
  drawerBg: '#fff',
  drawerHeader: '#757575',
  drawerActiveTint: '#fff',
  drawerInactiveTint: '#3a3a3a',
  drawerActiveBg: 'rgba(0, 0, 0, 0.2)',
  drawerInactiveBg: 'transparent',

  defaultTheme: 'light',
  loginTheme: 'light',
};
