import AccessSensor from './Pairing/AccessSensor.json';
import HualaiCamera from './Pairing/HualaiCamera.json';
import Hub from './Pairing/Hub.json';
import RemoteControl from './Pairing/RemoteControl.json';
import SercommAccessSensor from './Pairing/SercommAccessSensor.json';
import SercommDoorbell from './Pairing/SercommDoorbell.json';
import AddLocation from './AddLocation.json';
import Camera from './Camera.json';
import Common from './Common.json';
import Login from './Login.json';
import Mode from './Mode.json';
import PairingHelp from './PairingHelp.json';
import VideoStream from './VideoStream.json';

export default {
  AccessSensor,
  AddLocation,
  Camera,
  Common,
  HualaiCamera,
  Hub,
  Login,
  Mode,
  PairingHelp,
  RemoteControl,
  SercommAccessSensor,
  SercommDoorbell,
  VideoStream,
};
