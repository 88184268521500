import Box from '@mui/material/Box';

import logo from '../img/logo.png';

const style = {
  width: '100%',
  maxWidth: 380,
  marginBottom: 10,
};

export default function AuthHeader({vendorName}) {
  return (
    <Box mb={2} textAlign="center">
      <img style={style} src={logo} alt={vendorName} />
    </Box>
  );
}
