import {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import qs from 'qs';

import {Box, Button, IconButton, InputAdornment} from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';

import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-mui';

import {useApi} from '@scout-web/shared/context/api';
import {useConfig} from '@scout-web/shared/context/config';
import {useAuth} from '@scout-web/shared/context/member';

import AuthHeader from '../components/AuthHeader';

const initialValues = {
  email: '',
  password: '',
};

export default function Login({onForgotPassword, onLogin}) {
  const {t} = useTranslation('Login');
  const location = useLocation();
  const {pathname, search} = location.state?.from || {};
  const api = useApi();
  const {setToken} = useAuth();
  const {vendorName} = useConfig();
  const navigate = useNavigate();
  const validate = useCallback(() => ({}), []);
  const [type, setType] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const onSignInWithScout = useCallback(() => setType('member'), []);
  const onSignInWithWindstream = useCallback(() => {
    setLoading(true);
    api
      .get('/auth/windstream')
      .then(({data}) => (window.location.href = data.url))
      .catch(() => setLoading(false));
  }, [api]);
  const onCode = useCallback(
    code => {
      if (code) {
        setLoading(true);
        api
          .post('/auth/windstream', {code})
          .then(({data}) => {
            if (data.jwt) {
              setToken(data.jwt);
            }
            if (
              pathname &&
              pathname !== '/_oauth/windstream' &&
              !pathname.startsWith('/login/')
            ) {
              navigate(`${pathname}${search}`);
            }
          })
          .catch(err => {
            navigate('/');
            setLoading(false);
          });
      } else {
        navigate('/');
      }
    },
    [api, navigate, pathname, search, setToken],
  );
  useEffect(() => {
    if (pathname && search && !isLoading) {
      switch (pathname) {
        case '/_oauth/windstream': {
          const {code} = qs.parse(search.split('?')[1]);
          onCode(code);
          break;
        }
        default: {
          if (pathname.startsWith('/login/')) {
            const code = pathname.substring('/login/'.length);
            onCode(code);
          }
          break;
        }
      }
    }
  }, [isLoading, onCode, pathname, search]);
  return (
    <Box pt={5} pb={5}>
      <AuthHeader vendorName={vendorName} />
      {type !== 'member' && (
        <>
          <Button
            fullWidth
            variant="contained"
            disabled={isLoading}
            onClick={onSignInWithWindstream}
          >
            {t('Login:sign_in_with_windstream')}
          </Button>
          <br />
          <Button
            fullWidth
            variant="text"
            color="inherit"
            disabled={isLoading}
            onClick={onSignInWithScout}
            sx={{marginTop: 2}}
          >
            {t('Login:sign_in_with_scout')}
          </Button>
        </>
      )}
      {type === 'member' && (
        <Formik
          initialValues={initialValues}
          onSubmit={onLogin}
          validate={validate}
        >
          {({isSubmitting, submitForm}) => (
            <Form>
              <Field
                fullWidth
                component={TextField}
                name="email"
                type="email"
                label={t('common.email')}
                sx={{marginBottom: 1}}
                variant="standard"
              />
              <br />
              <Field
                fullWidth
                component={TextField}
                name="password"
                type="password"
                label={t('common.password')}
                sx={{marginBottom: 2}}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={onForgotPassword}>
                        <HelpOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <Button
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {t(
                  isSubmitting
                    ? 'Common:common.signing_in'
                    : 'Common:common.signin',
                )}
              </Button>
              <Button
                fullWidth
                variant="text"
                color="inherit"
                disabled={isLoading || isSubmitting}
                onClick={onSignInWithWindstream}
                sx={{marginTop: 2}}
              >
                {t('Login:sign_in_with_windstream')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
}
