/* eslint global-require: 0 */

const DEVICES = {
  DOORBELL: {
    SERCOMM: require('./img/devices/sercomm_doorbell.png'),
  },
  KEYPAD: require('./img/devices/keypad.png'),
  PANIC_BUTTON: require('./img/devices/panic_button.png'),
  REMOTE_CONTROL: {
    HEIMAN: {
      RC_EF_3_0: require('./img/devices/heiman_rc-ef-3.0_remote_control.png'),
      RC_EM: require('./img/devices/heiman_rc-em_remote_control.png'),
    },
  },
};

const VENDOR = {
  LOGO: require('./img/logo.png'),
};

export default {
  DEVICES,
  VENDOR,
};
