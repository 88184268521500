const name = 'Kinetic Secure Home';
const app_id = 'kinetic';
const barcode_format = ({model}) => {
  if (model === 'dbc831v2') {
    return /^([A-F0-9]{12})$/;
  }
};
const barcode_type = ({model}) => {
  if (model === 'dbc831v2') {
    return 'code128';
  }
  return 'qr';
};
// TODO - enable location adding
const canAddLocation = ({member}) => false; // member.scope === 'windstream';
const core_devices = [
  'access_sensor',
  'motion_sensor',
  'camera',
  'remote_control',
];
const default_hub = 'sercomm';
const default_state = 'AR';
const enabled_countries = 'US';
const enabled_devices = ({model, type}, env) => {
  if (type === 'hub') {
    return true;
  }
  if (type === 'camera') {
    if (model === 'SAMC01W-V3') {
      return true;
    }
  }
  if (type === 'doorbell') {
    if (model === 'dbc831v2') {
      return true;
    }
    if (model === 'SAWDB01-W') {
      return !!env.DEVELOPER;
    }
  }
  // TODO - add key_pad + limit glass_break & smoke alarm by model
  return ['access_sensor', 'motion_sensor', 'remote_control'].includes(type);
};
const free_camera_subscriptions = 999; // 5
const hub_pairing_mode = 'install_code';
const initial_devices = ['hub', 'camera', 'doorbell']; // disallow camera-only for now
const ssid_prefix = 'Scout';
const ssid_regexp = /^(Scout)-[a-f0-9]{6}$/;
const structure_types = ['A', 'S', 'M']; // skip business
const support_uri = 'https://www.windstream.com/support';

export default {
  app_id,
  barcode_format,
  barcode_type,
  canAddLocation,
  core_devices,
  default_hub,
  default_state,
  disable_signup: true,
  enabled_countries,
  enabled_devices,
  enable_camera_settings_on_home: true,
  enable_cameras_in_mode: false, // true,
  enable_custom_exit_delays: true,
  enable_custom_pro_entry_delays: true,
  enable_hub_wifi: true,
  free_camera_subscriptions,
  google_app_id: 'windstream-actions',
  google_display_name: 'Kinetic',
  has_monitoring_contract: true,
  has_monitoring_plans: false,
  has_permits: false,
  hub_pairing_barcode: true,
  hub_pairing_mode,
  initial_devices,
  name,
  prompts_on_redirect: false,
  remote_control_model: 'RC-EF-3.0',
  ssid_prefix,
  ssid_regexp,
  structure_types,
  support_uri,
  uses_pass_codes: true,
};
